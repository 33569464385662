<template>
  <div class="position:relative;">
      <router-link :to="{path: '/login' }" class="btn btn-dark " style="position:absolute; top:20px; right:20px;">Back to Login</router-link>
    <div class="nk-content ">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="content-page wide-md m-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">Terms &amp; Conditions</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      We are on a mission to make software cheaper and easier to use. The
                      following Terms, Conditions and Terms of Service, apply to all users.
                    </p>
                    <p class="text-soft ff-italic">Last Update: 01 April, 2020</p>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-inner card-inner-xl">
                    <div class="entry">
                      <h3>Terms and Conditions</h3>
                      <p>
                        Brief overview of Ts and Cs and terms of service etc.
                      </p>
                      <h4>Introduction</h4>
                      <p>
                        introductions etc
                      </p>
                      <p>
                        Second paragraph for terms and conditions.
                      </p>
                      <h4>Restrictions</h4>
                      <p>
                        Restrictions etc.
                      </p>
                      <ul class="list list-sm list-checked">
                        <li>
                          Lorem ipsum list
                        </li>
                      </ul>
                      <h4>Your Content</h4>
                      <p>
                          Some lorem ipsum content
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    getView (){ this.$http.get('/ViewAccess/termsandconditions')
        .then(() => { 
        })
        .catch(() => { 
        })},
  }, 
  created(){
    this.getView()
  }
};
</script>

<style></style>
